import { useCallback, useEffect, useRef } from 'react';
import { initializeAdForm, sendEventAdForm } from './adForm';
import { AnalyticsKey, AnalyticsPageViewEvents } from './enum';
import useGlobalSettings from 'store/globalSettings';
import { IGlobalSettingsMeta } from 'api/settings';

const useAnalytics = () => {
  const [
    {
      data: { meta = {} as IGlobalSettingsMeta },
    },
  ] = useGlobalSettings();

  const initializeRef = useRef<Record<string, Promise<void>>>({});

  const sendEvent = useCallback(
    async (params: Partial<Record<AnalyticsKey, string | AnalyticsPageViewEvents>>) => {
      if (meta.adFormAnalyticsKey) {
        await initializeRef.current[meta.adFormAnalyticsKey];
        sendEventAdForm({
          analyticKey: meta.adFormAnalyticsKey,
          params,
        });
      }
    },
    [meta.adFormAnalyticsKey]
  );

  useEffect(() => {
    async function initialize() {
      if (meta.adFormAnalyticsKey) {
        initializeRef.current[meta.adFormAnalyticsKey] = initializeAdForm();
        await initializeRef.current[meta.adFormAnalyticsKey];
      }
    }

    initialize();
  }, [meta.adFormAnalyticsKey]);

  return { sendEvent };
};

export default useAnalytics;
