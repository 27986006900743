import { AnalyticsKey, AnalyticsPageViewEvents } from './enum';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _adftrack: any[];
    adf: {
      async: {
        track: () => void;
      }
    }
  }
}

const AdFormAnalyticsKey = {
  [AnalyticsKey.pageView]: 'pagename',
};

const AdFormAnalyticsEvents = {
  [AnalyticsPageViewEvents.registrationStart]: 'Registration Start',
  [AnalyticsPageViewEvents.registrationSuccess]: 'Registration Confirmed',
  [AnalyticsPageViewEvents.homePageView]: 'Home page',
  [AnalyticsPageViewEvents.firstTimeDeposit]: 'First-Time Deposit',
  [AnalyticsPageViewEvents.deposit]: 'Deposit',
};

const changeParams = (params: Partial<Record<AnalyticsKey, string | AnalyticsPageViewEvents>>) => {
  return Object.keys(params).reduce((acc, key) => {
    const newKey = AdFormAnalyticsKey[key as AnalyticsKey];
    if (newKey) {
      acc[newKey] = AdFormAnalyticsEvents[params[key as AnalyticsPageViewEvents]] || params[key as AnalyticsKey];
    }
    return acc;
  }, {} as Record<AnalyticsKey, string>);
};

const initializeAdForm = async () => {
  return new Promise<void>((resolve) => {
    if (window._adftrack) return resolve();

    const script = document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';

    script.onload = () => {
      window._adftrack = Array.isArray(window._adftrack)
        ? window._adftrack
        : window._adftrack
        ? [window._adftrack]
        : [];
      resolve();
    };
    script.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js';
    document.body.appendChild(script);
  });
};

const sendEventAdForm = ({
  analyticKey,
  params,
}: {
  analyticKey: string;
  params: Partial<Record<AnalyticsKey, string | AnalyticsPageViewEvents>>;
}) => {
  if (!window._adftrack) return;

  window._adftrack.push({
    HttpHost: 'a2.adform.net',
    pm: analyticKey,
    divider: encodeURIComponent('|'),
    ...changeParams(params),
  });

  window.adf.async.track();
};

export { initializeAdForm, sendEventAdForm };
